@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://genesis-cdn.faradayfuturecn.com/assets/fonts/MaterialIcons/MaterialIcons-Regular.woff) format('woff'),
       url(https://genesis-cdn.faradayfuturecn.com/assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*
  Roboto 600 does not exist, so we are matching it to 700 (bold).
  Existing styles with 600 should be updated.
*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin/roboto-v27-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-300 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-regular - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-500 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-700 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://genesis-cdn.faradayfuturecn.com/assets/fonts/Roboto/latin-ext/roboto-v27-latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

