// Self-hosted for CN
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700|Material+Icons');

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

// End Google Fonts

@color-ff: #7F2A00;
@color-gray: #777777;
@color-light: #d9d9d9;
@margin-default: 36px;

// Prevent padding on right side of page (iPhone)
// Avoid putting in body - will break 'scrollTop'
#app {
  overflow-x: hidden;
}

/**************** GENERAL LAYOUT ****************/
// Responsive mixin
.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}
.ant-layout{
  justify-content: center;
}
.ant-layout-content{
  padding-top: @margin-default * 3;
  padding-left: @margin-default * 3;
  padding-right: @margin-default * 3;
  padding-bottom: @margin-default * 5;
  &.reserve{
    background-color: #1b1d1f;
  }
  &.no-padding{
    padding-left: 0;
    padding-right: 0;
  }
  &.full-width{
    // margin-top: @margin-default * -4;
    margin-left: @margin-default * -3;
    margin-right: @margin-default * -3;
    .responsive(1199px, {
      // margin-top: @margin-default * -3;
      margin-left: @margin-default * -2;
      margin-right: @margin-default * -2;
    });
    .responsive(767px, {
      // margin-top: @margin-default * -2;
      margin-left: @margin-default * -1;
      margin-right: @margin-default * -1;
    });
    .responsive(479px, {
      margin: -10px -10px 0 -10px;
    });
  }
  &.full{
    margin-top: @margin-default * -3;
    margin-left: @margin-default * -3;
    margin-right: @margin-default * -3;
    margin-bottom: @margin-default * -5;
    .responsive(1199px, {
      margin-top: @margin-default * -3;
      margin-left: @margin-default * -3;
      margin-right: @margin-default * -3;
      margin-bottom: @margin-default * -5;
    });
    .responsive(767px, {
      margin-top: @margin-default * -3;
      margin-left: @margin-default * -3;
      margin-right: @margin-default * -3;
      margin-bottom: @margin-default * -5;
    });
    .responsive(479px, {
      margin-top: @margin-default * -3;
      margin-left: @margin-default * -3;
      margin-right: @margin-default * -3;
      margin-bottom: @margin-default * -5;
    });
  }

  .responsive(1199px, {
    // padding-top: @margin-default * 3;
    padding-left: @margin-default * 2;
    padding-right: @margin-default * 2;
    padding-bottom: @margin-default * 4;
  });

  .responsive(767px, {
    // padding-top: @margin-default * 2;
    padding-left: @margin-default;
    padding-right: @margin-default;
    padding-bottom: @margin-default * 2;
  });

  .responsive(479px, {
    padding: 10px;
    padding-top: 84px;
  });

}
.ant-row:not([type=flex]) {
  .responsive(479px, {
    margin-left: 0 !important;
    margin-right: 0 !important;
  });
}
.card {
  background: #fff;
  padding: @margin-default;
  padding-top: @margin-default * 3;
  border-top: 3px solid @color-ff;
  margin-bottom: @margin-default;
  .responsive(479px, {
    padding: 10px;
  });
}
.darker-row{
  background-color: #f7f7f7;
  margin: @margin-default 0 0 0 !important;
  padding: @margin-default;

  .responsive(479px, {
    padding: 10px;
  });
}
.table-rule{
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: @margin-default * 0.5;
}
.account-row{
  min-height: 230px;
  padding-left: 20px;
  padding-right: 20px;
  .responsive(991px, {
    min-height: auto;
  });
  > p{
    padding-bottom: @margin-default;
  }
}
.edit-account{
  margin-top: 21px;
  .ant-checkbox{
    transform: scale(1.3);
    vertical-align: baseline;
  }
  span{
    font-size: 17px;
  }
}
.ant-layout-footer{
  height: 300px;
  color: #fff;
}
.with-header {
  margin-top: @margin-default * 3;
  .responsive(1199px, {
    margin-top: @margin-default * 3;
  });
  .responsive(767px, {
    margin-top: @margin-default * 2;
  });
  .responsive(479px, {
    margin-top: @margin-default;
  });
}

/**************** TYPOGRAPHY ****************/
h1{
  font-size: 40px;
  margin-bottom: @margin-default;
}
h2{
  font-size: 26px;
}
h3{
  font-size: 15px;
  font-weight: 700;
}
p{
  font-size: 15px;
  &.tall{
    line-height: 2;
  }
  &.lg{
    font-size: 20px;
    line-height: 2em;
  }
  &.table{
    margin-top: @margin-default * 0.5;
    font-size: 17px;
    line-height: 2;
  }
  &.faded{
    opacity: 0.5;
  }
  &.center{
    text-align: center;
  }
  &.gray{
    color: @color-gray;
  }
}
b{
  font-weight: 700;
}
button{
  font-size: 12px;
  font-weight: 700;
}
input{
  font-size: 14px;
  &.edit-account{
    font-size: 17px;
    margin-top: 13px;
    margin-bottom: 4px;
  }
}
ul{
  list-style: initial;
  li{
    margin-left: 1em;
  }
}
.ant-breadcrumb{
  font-size: 17px;
  margin: @margin-default * 0.5 0px;
}
.top-margin{
  margin-top: @margin-default;
  margin-bottom: 0px;
}
.top-margin-1{
  margin-top: @margin-default;
}
.top-margin-2{
  margin-top: @margin-default * 2;
}
.no-top-margin{
  margin-top: 0px !important;
}
.little-spacey{
  width: 90px;
  height: 2px;
  background-color: @color-ff;
  margin: @margin-default 0;
}
.right-align{
  float: right;
}
.copper{
  color: @color-ff;
  font-weight: 500;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/**************** RESERVE STEPS HEADER ****************/
.ant-layout-content.reserve{
  color: #fff;
  padding-top: @margin-default * 2;
  padding-bottom: 0px;
  border-top: 3px solid @color-ff;
  h1{
    color: #fff;
  }
  h3, p{
    color: @color-gray;
  }
  .underbar{
    width: 100%;
    height: 4px;
    margin-top: @margin-default / 3;
    margin-bottom: @margin-default;
    background-color: @color-gray;
  }
  .active{
    h3, p{
      color: @color-ff;
    }
    .underbar{
      background-color: @color-ff;
    }
  }
}

/**************** RESERVE SECTION ****************/
.reservation-row{
  margin: @margin-default 0px 0px 0px !important;
}
.reservation-col{
  background-color: #f7f7f7;
  padding: (@margin-default / 2) 20px !important;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  white-space: nowrap;
}

/**************** FORM ELEMENTS ****************/
.ant-btn{
  margin: 10px 0;
  width: 100%;
  height: auto;
  background-color: @color-ff;
  color: #fff;
  text-transform: uppercase;
  &.dark{
    background-color: @color-gray;
    border:1px solid @color-gray;
    &:hover{
      background-color: #fff;
      color: @color-gray;
    }
  }
  &.inverted{
    color: @color-ff;
    background-color: #fff;
    border-radius: 0;
    &:hover{
      color: #fff;
      background-color: @color-ff;
      border-radius: 0;
    }
  }
  &.mini{
    width: auto;
    padding: 3px 9px;
    float: right;
    margin: 0;
  }
  &.disabled{
    pointer-events: none;
    opacity: 0.5;
  }
  &.top-margin {
    margin-top: 36px;
  }
}
.ant-back-top-content{
  border-radius: 0;
}
.ant-input,
.ant-select-selector{
  border-radius: 0px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom-width: 2px !important;
}

// Minimum size to prevent iPhone zoom
.ant-input,
.ant-input-prefix,
.ant-select-item,
.ant-select-dropdown,
.ant-select-selector input,
.ant-select-selector{
  font-size: 16px;
}

.ant-input-lg{
  padding: 10px 0px;
  height: auto;
}
.ant-select-lg > .ant-select-selection--single{
  margin: 10px 0;
  height: 43px;
}
.ant-select-selection > .ant-select-selection__rendered {
  line-height: 43px;
}
.ant-select-selector{
  padding: 0 !important;
  margin: 5px 0 !important;
}
.fake-input{
  font-size: 14px;
  padding: 10px 0;
  line-height: 1.5;
  border-bottom: 2px solid @color-light;
  user-select: none;
  -webkit-user-select: none;
}

.ant-form-item-has-error .ant-input-prefix {
  color: @text-color;
}

.ant-input-affix-wrapper, .ant-input-affix-wrapper-lg {
  border-top: none;
  border-right: none;
  border-left: none;
  border-width: 2px;
  border-bottom-width: 2px; /* Add border-bottom-width to Inputs that have a "prefix" */
  padding-left: 0;
  padding-right: 0;
}

/**************** ERROR MESSAGES ****************/
.ant-alert{
  &.ant-alert-error{
    background-color: #f8f8f8;
    border: none;
    border-top: 3px solid @color-ff;
    border-bottom: 1px solid #777777;
  }
  &.ant-alert-success{
    padding: 20px;
    border: none;
    border-top: 3px solid #799b52;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.36);
  }
}

/**************** OTHER ****************/
.ant-back-top{
  right: 20px;
  bottom: 20px;
}
.ant-modal-content {
  border-top: 4px solid @color-ff;
  padding: @margin-default * 1;
}
.ant-modal-header {
  border: none;
}
.ant-modal-title {
  font-size: 26px;
  line-height: 1.2;
  border: none;
}
.ant-modal-body {
  .ant-btn {
    margin-top: @margin-default;
    margin-bottom: 0;
  }
}
.ant-modal-root {
  z-index: 20;
}

.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
}

/**************** BREADCRUMBS ****************/
.breadcrumb-row {
  display: flex;
  justify-content: space-between;
}

a.breadcrumb-logout {
  color: black;
  margin-top: 18px;
  &:hover {
    color: @color-ff;
  }
}

/******************Modal Success *****************/
.refundSuccess {
  button {
    padding: 10px 25px;
  }
}

/****************** Unset modal hover link color *****************/
#ff-ui-modal-root {
  a:hover {
    color: unset !important;
  }
}